import {
  Box,
  Center,
  Stack,
  Text,
  Image,
  Link,
  Button,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  VStack,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";

import heroSoporte from "../assets/images/hero-Soporte.png";
import CustomParallax from "../components/custom/CustomParallax";
import Hero from "../components/layout/Hero";
import Section from "../components/section/Section";
import SectionHeader from "../components/section/SectionHeader";

import questions1 from "../assets/images/questions (1).png";
import questions2 from "../assets/images/questions (2).png";
import questions3 from "../assets/images/questions (3).png";
import questions4 from "../assets/images/questions (4).png";
import questions5 from "../assets/images/questions (5).png";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import Footer from "../components/layout/Footer";
import { routes } from "../routes/routes";
import { useNavigate } from "react-router-dom";
import ContactDataSection from "../components/section/ContactDataSection";

interface FrequentQuestionsItem {
  title: string;
  links: string;
  image: string;
}

interface IAccordionItem {
  title: string;
  content: string;
}
interface IAccordionListItem {
  title: string;
  content: string[];
}
interface IAccordion {
  title: JSX.Element;
  content: (IAccordionItem | IAccordionListItem)[];
}

const accordionLists: IAccordion[] = [
  {
    title: (
      <>
        Sobre{" "}
        <Text color="orange.500" as="span">
          PagoFlash
        </Text>
      </>
    ),
    content: [
      {
        title: "- ¿Qué es PagoFlash?",
        content:
          "PagoFlash, actuando como PSP autorizado por el Banco Central de Venezuela, provee servicios de pago basados en tecnología móvil y web con la finalidad de facilitar la interacción entre consumidores y comercios en Internet.",
      },
      // {
      //   title: "- ¿Cuáles son los métodos de pago que aceptamos?",
      //   content:
      //     "A través de nosotros podrás recibir pagos con tarjetas de crédito Visa o MasterCard y depósitos o transferencias.",
      // },
      // {
      //   title: "- ¿Cuáles son las formas de pago que aún NO aceptamos?",
      //   content:
      //     "Actualmente, no aceptamos pagos a través de tarjetas de crédito extranjeras ni tarjetas de débito en Venezuela.",
      // },
      {
        title: "- ¿Aceptamos pagos en moneda extranjera?",
        content: "No, actualmente sólo aceptamos pago en bolívares.",
      },
      // {
      //   title: "- ¿Cuáles son los costos del servicio?",
      //   content:
      //     "Cobramos un único pago por afiliación que depende del tipo de negocio que tengas y un porcentaje por transacción. En tarjeta de crédito: 9% y en transferencia y depósito: 3,92%.",
      // },
      {
        title: "- ¿Qué requisitos solicitamos?",
        content:
          "Persona Jurídica: Acta constitutiva y estatutos sociales debidamente inscritos en el Registro Mercantil competente.Documento inscrito en el Registro Mercantil, o copia de poder autenticado, donde se identifique el sujeto que puede representar a la empresa así como las facultades que detenta. Cédula de Identidad del Representante Legal. Registro Único de Información Fiscal (RIF) vigente. Estados Financieros de los tres (3) últimos ejercicios económicos. Persona Natural: Cedula y RIF",
      },
      {
        title: "- ¿Cuál es el costo de Pagoflash?",
        content:
          "Nos adecuamos a tu modelo de negocio, los costos están asociados a nivel de transacciones.",
      },
      {
        title: "- ¿Cuán fácil es la integración del botón de pago?",
        content:
          "La integración es bastante sencilla, ya que Pago Flash es un API, y contamos con el equipo especializado para que rápidamente puedas utilizar el botón de pago sin pasos complicados.",
      },
      {
        title: "- ¿Ofrecen Soporte técnico en caso de alguna falla?",
        content:
          "Si, contamos con un equipo que estará atento a cualquier eventualidad.",
      },
      {
        title:
          "- ¿Cuánto tiempo toma recibir los fondos luego de una transacción exitosa?",
        content:
          "La respuesta es instantánea ya que el sistema está diseñado para que la conciliación sea Flash.",
      },
      {
        title:
          "- ¿Hay opciones para personalizar Pagoflash dentro de un sitio web?",
        content:
          "Si, se puede configurar y colocar el Logo y colores de la empresa para que sea transparente la experiencia de pago de tus clientes.",
      },
    ],
  },
  {
    title: (
      <>
        Sobre{" "}
        <Text color="orange.500" as="span">
          nuestros productos
        </Text>
      </>
    ),
    content: [
      {
        title:
          "- ¿Todos los productos requieren de un desarrollador para integrarlos?",
        content:
          "No. Con PagoClick y PagoMail solo necesitas registrate en PagoFlash y tú mismo podrás gestionarlos y configurarlos sin ninguna complicación. Para integrar PagoShop, si necesitarás de la ayuda de un desarrollador..",
      },
      // {
      //   title:
      //     "- ¿Cómo debo integran PagoSop (nuestro botón de pago) en mi ecommerce?",
      //   content:
      //     "No. Con PagoClick y PagoMail solo necesitas registrate en PagoFlash y tú mismo podrás gestionarlos y configurarlos sin ninguna complicación. Para integrar PagoShop, si necesitarás de la ayuda de un desarrollador.",
      // },
      {
        title: "- ¿Es necesario contar con el certificado SSL?",
        content:
          "Para integrar con nosotros no es necesario que cuentes con el certificado SSL.",
      },
      // {
      //   title: "- ¿Es posible integrar PagoFlash desde un app móvil?",
      //   content:
      //     "Aunque actualmente no poseemos un SDK o plugin para apps móviles, puedes integrar a través de un webview en tu aplicación.",
      // },
    ],
  },
  // {
  //   title: (
  //     <>
  //       Sobre{" "}
  //       <Text color="orange.500" as="span">
  //         transacciones y pagos en mi cuenta
  //       </Text>
  //     </>
  //   ),
  //   content: [
  //     {
  //       title: "- ¿En cuánto tiempo podré recibir mi dinero?",
  //       content:
  //         "Realizamos las transferencias de los fondos dependiendo de tu tipo de negocio. Con tarjetas de créditos si eres persona natural 5 días hábiles y si eres persona jurídica 48 horas. Con transferencias y depósitos para ambas es de 72 horas.",
  //     },
  //     {
  //       title: "- ¿Debo tener cuenta en algún banco en específico?",
  //       content:
  //         "No. Puedes registrar cualquier tipo de cuenta de cualquier banco en Venezuela.",
  //     },
  //     {
  //       title: "- ¿En cuánto tiempo la transacción es aprobada?",
  //       content:
  //         "Si el pago es con tarjeta de crédito la transacción es aprobada al instante. Si es con depósito o transferencia, la confirmación se realiza en un lapso de 24 horas aproximadamente.",
  //     },
  //     {
  //       title:
  //         "- ¿En cuánto tiempo veo reflejada en mi cuenta PagoFlash las transacciones?",
  //       content:
  //         "Si el pago se realizó con tarjeta de crédito podrás verlo reflejado en tu cuenta de inmediato. En el caso de transferencias y depósitos, lo verás reflejado en el momento en que sea verificado (por lo general tarda un día hábil)..",
  //     },
  //     {
  //       title: "- ¿Como sé que recibí un pago?",
  //       content:
  //         "Recibirás una notificación a tu correo electrónico una vez sea aprobada la transacción.",
  //     },
  //     {
  //       title: "- Una vez que mi cliente ha pagado, ¿Debo hacer algo más?",
  //       content:
  //         "Cuando tu cliente ha realizado sus pagos a través de PagoFlash, la liquidación de tu dinero se hará automáticamente a la cuenta bancaria que hayas afiliado en la plataforma, por lo que solamente debes esperar que se cumplan con los tiempos de conciliación dependiendo de la modalidad con la que adquieras nuestros servicios (Persona natural o jurídica) y el método de pago tu cliente haya utilizado (Tarjeta de crédito, depósito o transferencia). Adicionalmente, no cobramos mensualidad ni debes realizar otro pago extra para tu dinero sea liquidado.",
  //     },
  //   ],
  // },
  {
    title: (
      <>
        Sobre{" "}
        <Text color="orange.500" as="span">
          la seguridad de tus pagos
        </Text>
      </>
    ),
    content: [
      {
        title:
          "- ¿Cómo protegemos el dinero de nuestros compradores y vendedores?",
        content:
          "Contamos con certificación SSL, protocolos criptográficos que proporcionan comunicaciones seguras en la red para los datos de los usuarios y clientes, además del constante monitoreo y seguimiento de las transacciones para evitar fraudes bancarios o contracargos.",
      },
      // {
      //   title: "- ¿Cómo puedo cambiar los datos en mi cuenta PagoFlash?",
      //   content:
      //     "Al ingresar a nuestra plataforma, en la página de inicio a la izquierda podrás ver tu foto de perfil y abajo el botón «Ver mi cuenta» que al presionar te dirigirá a tu perfil. Al lado izquierdo podrás ver el botón “Editar mi cuenta” y ahí podrás cambiar tus datos personales correspondientes. Además, en ese misma ventana tienes la opción de modificar tus datos bancarios y contraseña.",
      // },
    ],
  },
  // {
  //   title: (
  //     <>
  //       {" "}
  //       Sobre{" "}
  //       <Text color="orange.500" as="span">
  //         el registro
  //       </Text>
  //     </>
  //   ),
  //   content: [
  //     // {
  //     //   title:
  //     //     "- ¿Cómo protegemos el diner de nuestros compradores y vendedores?",
  //     //   content: [
  //     //     "Ingresa a www.PagoFlash.com",
  //     //     "Busca en la esquina superior derecha de la pantalla la opción “Regístrate”.",
  //     //     "Llena el formulario de registro siguiendo las instrucciones que te facilita la página y cuando termines haz click en “Registrar”.",
  //     //     "Se generará un correo electrónico de confirmación que llegará a la bandeja de la dirección colocada en el formulario. Ingresa a ese correo y confirma la cuenta.",
  //     //   ],
  //     // },
  //     {
  //       title: "- ¿Cómo puedo cambiar los datos en mi cuenta PagoFlash?",
  //       content: [
  //         "Contáctanos a nuestro teléfono (0212) 8863762 o escríbenos a ventas@pagoFlash.com",
  //         "También puedes esperar a que nosotros te contactemos lo más pronto posible.",
  //         "Realiza el pago de afiliación y envía el comprobante a tu ejecutivo de cuenta.",
  //         "Consigna los recaudos que la empresa solicita dependiendo del tipo de persona (jurídica o natural).",
  //       ],
  //     },
  //   ],
  // },
];

export const frequentQuestions: FrequentQuestionsItem[] = [
  {
    image: questions1,
    title: "Sobre PagoFlash",
    links: "https://www.pagoflash.com/soporte",
  },
  {
    image: questions2,
    title: "Nuestros productos",
    links: "https://www.pagoflash.com/soporte",
  },
  // {
  //   image: questions3,
  //   title: "Transacciones y pagos",
  //   links: "https://www.pagoflash.com/soporte",
  // },
  {
    image: questions4,
    title: "Sobre la seguridad",
    links: "https://www.pagoflash.com/soporte",
  },
  // {
  //   image: questions5,
  //   title: "Sobre el registro",
  //   links: "https://www.pagoflash.com/soporte",
  // },
];

const SoportePage = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Hero backgroundImage={heroSoporte}>
        <Box textAlign="center">
          <Text
            color={"white"}
            fontWeight={500}
            lineHeight={1.3}
            fontSize={{ base: "3xl", md: "6xl" }}
            mb="8"
            opacity="0.85"
          >
            ¿En qué podemos ayudarte?
          </Text>
          <Box bgColor="white" my="20" mx="auto" borderRadius="2xl">
            <Text color="blackAlpha.700" fontSize="4xl" p="8">
              Pregúntanos lo que necesites saber 🙂
            </Text>
          </Box>
        </Box>
      </Hero>

      <Box id="hero-bottom" position="relative" zIndex="1">
        <CustomParallax>
          <Section spacing="sm">
            <SectionHeader color="white" textAlign="center" showDivider mb="16">
              Estas son las preguntas que nos hacen frecuentemente
            </SectionHeader>

            <Stack
              direction={{ base: "column", lg: "row" }}
              justifyContent="center"
              spacing={{ base: "12", lg: "8" }}
              alignItems={{ base: "center", lg: "flex-start" }}
              width="80%"
              textAlign="center"
              margin="auto"
            >
              {frequentQuestions.map(({ links, image, title }, i) => (
                <Box key={i} w="100%">
                  <Link isExternal href={links} textDecoration="none">
                    <Center fontSize="6xl" mb="8">
                      <Image src={image} boxSize="16"></Image>
                    </Center>
                    <Center flexDirection="column">
                      <Text
                        fontSize="2xl"
                        fontWeight="medium"
                        pb={"5"}
                        color="white"
                        textDecoration="none"
                      >
                        {title}
                      </Text>
                    </Center>
                  </Link>
                </Box>
              ))}
            </Stack>
          </Section>
        </CustomParallax>
      </Box>

      {accordionLists.map((accordion, i) => (
        <Section position="relative" bg="white" key={i}>
          <SectionHeader color="black" showDivider textAlign="center">
            <Text bg="teal.300" py="4" fontSize={{ base: "4xl", lg: "6xl" }}>
              {accordion.title}
            </Text>
          </SectionHeader>

          <VStack
            direction={{ base: "column", lg: "row" }}
            justifyContent="center"
            maxW="4xl"
            mx="auto"
          >
            {accordion.content.map(({ title, content }, i) => (
              <Accordion
                allowMultiple
                w="100%"
                borderColor="orange.500"
                key={i}
              >
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left" p="2">
                            <Text fontSize={{ base: "xl", lg: "2xl" }}>
                              {title}
                            </Text>
                          </Box>
                          {isExpanded ? (
                            <MinusIcon fontSize="12px" />
                          ) : (
                            <AddIcon fontSize="12px" />
                          )}
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4} px="5" py="5">
                        {Array.isArray(content) && (
                          <UnorderedList fontSize={{ base: "lg", lg: "xl" }}>
                            {content.map((text, i) => (
                              <ListItem key={i}> {text}</ListItem>
                            ))}
                          </UnorderedList>
                        )}

                        {!Array.isArray(content) && (
                          <Text fontSize="xl">{content}</Text>
                        )}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            ))}
          </VStack>
        </Section>
      ))}

      <CustomParallax>
        <Section spacing="sm">
          <SectionHeader color="white" textAlign="center" showDivider>
            ¿No pudiste encontrar una respuesta a tu pregunta? <br></br>
            <Text fontSize="3xl" as="span" color="blackAlpha.700">
              Contáctanos por el canal que sea más cómodo para ti.
            </Text>
          </SectionHeader>

          <ContactDataSection email="soporte@pagoflash.com" />

          <Box textAlign="center">
            <Button
              color="white"
              size="lg"
              px={{ base: "8", md: "16" }}
              py={{ base: "8", md: "16" }}
              onClick={() => navigate(routes.asesoria)}
            >
              <Text color="black" fontSize={{ base: "xl", lg: "2xl" }}>
                Agenda una asesoría
              </Text>
            </Button>
          </Box>
        </Section>
      </CustomParallax>

      <Footer />
    </Box>
  );
};

export default SoportePage;
