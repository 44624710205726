import { Center, Heading, Button, Stack } from "@chakra-ui/react";
import Section from "./Section";

import bgBottom from "../../assets/images/pagoclick-bottom.jpg";
import { routes } from "../../routes/routes";
import { useNavigate } from "react-router-dom";

const PreFooterSection = () => {
  const navigate = useNavigate();

  return (
    <Section
      bgImage={`url(${bgBottom})`}
      bgPosition="center center"
      bgSize="cover"
      h="694px"
    >
      <Center flexDirection="column" h="100%">
        <Heading size="3xl" mb="16" color="white" textAlign="center">
          ¿Tienes dudas?
        </Heading>
        <Stack direction={{ base: "column", md: "row" }} spacing="8">
          <Button
            bg="orange.500"
            color="white"
            size="lg"
            onClick={() => navigate(routes.asesoria)}
            _hover={{ bg: "orange.300" }}
          >
            Agenda una asesoría
          </Button>
          {/* <Button bg="white" color="orange.500" size="lg">
            Preguntas frecuentes
          </Button> */}
        </Stack>
      </Center>
    </Section>
  );
};

export default PreFooterSection;
