import {
  Box,
  Button,
  Center,
  Heading,
  Image,
  ListItem,
  OrderedList,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import Hero from "../components/layout/Hero";

import SectionHeader from "../components/section/SectionHeader";
import Section from "../components/section/Section";
import DeviceFrame from "../components/devices/DeviceFrame";

import bgHero from "../assets/images/hero-pagoshop.jpg";

import Footer from "../components/layout/Footer";

import PreFooterSection from "../components/section/PreFooterSection";
import CustomPulse from "../components/custom/CustomPulse";
import PagoShopTooltip from "../components/pagoshop/PagoShopTooltip";
import CustomParallax from "../components/custom/CustomParallax";
import ConditionsSection from "../components/section/ConditionsSection";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import CustomMobileTooltip from "../components/custom/CustomMobileTooltip";

import pagoServicio from "../assets/images/pagoservicio.png";
import pagoQR from "../assets/images/qr.png";
import pagoClick from "../assets/images/img-pagoclick.png";
import pagoShop from "../assets/images/img-pagoshop.png";
import pagoMail from "../assets/images/img-pagomail.png";

import pagoShopCart from "../assets/images/pagoshop-cart.png";
import pagoShop24 from "../assets/images/pagoshop-24.png";
import pagoShopForm from "../assets/images/pagoshop-form.png";
import pagoShopReport from "../assets/images/pagoshop-report.png";
import imgDeviceLaptop from "../assets/images/imgDeviceLaptop.jpg";

interface PagoShopBenefit {
  text: JSX.Element;
  image: string;
}

const pagoShopBenefits: PagoShopBenefit[] = [
  {
    text: <>Integración sencilla.</>,
    image: pagoShopCart,
  },
  {
    text: (
      <>
        Ventas <br />
        24/7.
      </>
    ),
    image: pagoShop24,
  },
  {
    text: <>Formulario amigable.</>,
    image: pagoShopForm,
  },
  {
    text: <>Reporte de tus ventas.</>,
    image: pagoShopReport,
  },
];

interface SiteService {
  icon: string;
  text: string;
  content: string;
}

const siteServices: SiteService[] = [
  {
    icon: pagoQR,
    text: "PagoQR",
    content: "QR personalizado que permite recibir pagos.",
  },
  {
    icon: pagoClick,
    text: "PagoClick",
    content:
      "Enlace personalizado que permite recibir pagos en redes sociales, chats e inclusive establecimientos físicos.",
  },
  {
    icon: pagoShop,
    text: "PagoShop",
    content: "Botón de pago que puede ser integrado al sitio de ecommerce.",
  },
  {
    icon: pagoMail,
    text: "PagoMail",
    content: "Permite enviar cobros al correo electrónico de los clientes.",
  },
  {
    icon: pagoServicio,
    text: "PagoServicio",
    content:
      "Permite a los clientes realizar el pago de los distintos servicios, por la modalidad de Pago Móvil. ",
  },
];

const PagoFlashNomina = () => {
  const navigate = useNavigate();
  const noWrapClass = useBreakpointValue({ base: "nowrap", lg: "" });

  return (
    <Box>
      <Hero backgroundImage={bgHero}>
        <Box textAlign="center">
          <Heading size="3xl" color="white" textAlign="center" mb="8">
            PagoFlash Nomina
          </Heading>
          <Text
            color={"white"}
            fontWeight={600}
            lineHeight={1.3}
            fontSize={{ base: "2xl", md: "3xl" }}
            mb="8"
            opacity="0.85"
          >
            Es un servicio mediante el cual PagoFlash International C.A.,
            garantiza al cliente (Persona Jurídica) el abono de sueldos,
            salarios, beneficios, entre otros, en las cuentas transaccionales de
            sus trabajadores, según los montos indicados en una relación
            electrónica o archivo electrónico.
          </Text>

          <Stack
            direction={{ base: "column", md: "row" }}
            justifyContent="center"
            spacing="8"
          >
            <Button
              rounded="sm"
              variant="outline"
              color="white"
              borderWidth="2px"
              size="lg"
              px="16"
              _hover={{ bg: "white", color: "black" }}
            >
              Ver Tutorial
            </Button>

            <Button
              bg="orange.500"
              rounded="sm"
              color="white"
              size="lg"
              _hover={{ bg: "orange.600" }}
              onClick={() => navigate(routes.asesoria)}
            >
              Agenda una asesoría
            </Button>
          </Stack>
        </Box>
      </Hero>

      {/* <Section
        id="hero-bottom"
        position="relative"
        zIndex="1"
        bg="white"
        pb="8"
      >
        <Box maxW="4xl" mx="auto">
          <SectionHeader color="gray.700" textAlign="center" showDivider>
            <Text as="span" color="orange.500">
              ¿Cómo
            </Text>{" "}
            funciona?
          </SectionHeader>

          <Box textAlign="center" my={{ base: "16", lg: "32" }}>
            <Box
              position="relative"
              display={{ base: "none", md: "inline-block" }}
            >
              <CustomPulse
                top="4"
                left="4"
                zIndex="40"
                position="absolute"
                sizing={30}
              >
                <PagoShopTooltip
                  isOpen={undefined}
                  label={
                    <Text>
                      Descargue y realice el Llenado del formulario de Registro
                      integral de persona natural.
                    </Text>
                  }
                >
                  1
                </PagoShopTooltip>
              </CustomPulse>

              <CustomPulse
                top="4"
                right="4"
                zIndex="40"
                position="absolute"
                sizing={30}
              >
                <PagoShopTooltip
                  px="4"
                  fontSize="xs"
                  isOpen={undefined}
                  label={
                    <Text>
                      Recibirás un correo de confirmación con el link de ingreso
                      y credenciales para hacer uso de la plataforma y realizar
                      la descarga del API para integrarte a nuestro sistema y
                      recibir PAGOS, por medio de Enlaces de Cobro a través de
                      QR, Redes Sociales, Correo Electrónico y Ecommerce.
                    </Text>
                  }
                >
                  2
                </PagoShopTooltip>
              </CustomPulse>

              <CustomPulse
                bottom="4"
                left="4"
                zIndex="40"
                position="absolute"
                sizing={30}
              >
                <PagoShopTooltip
                  isOpen={undefined}
                  label={
                    <Text>
                      Envíe el formulario a nuestro correo
                      atencionalcliente@pagoflash.com , Junto a los siguientes
                      recaudos:
                      <OrderedList>
                        <ListItem>
                          Acta constitutiva y estatutos sociales debidamente
                          inscritos en el Registro Mercantil competente.
                        </ListItem>

                        <ListItem>
                          Documento inscrito en el Registro Mercantil, o copia
                          de poder autenticado, donde se identifique el sujeto
                          que puede representar a la empresa así como las
                          facultades que detenta.
                        </ListItem>

                        <ListItem>
                          Cédula de Identidad del Representante Legal.
                        </ListItem>

                        <ListItem>
                          Registro Único de Información Fiscal (RIF) vigente.
                        </ListItem>

                        <ListItem>
                          Estados Financieros de los tres (3) últimos ejercicios
                          económicos.
                        </ListItem>
                      </OrderedList>
                    </Text>
                  }
                >
                  3
                </PagoShopTooltip>
              </CustomPulse>
              <DeviceFrame
                device="MacBook Pro"
                color="black"
                size={useBreakpointValue({ base: 3, md: 2.5, lg: 2 })}
              >
                <Box
                  bgImage={`url(${imgDeviceLaptop})`}
                  height="100%"
                  width="100%"
                  backgroundSize={"cover"}
                ></Box>
              </DeviceFrame>
            </Box>

            <CustomMobileTooltip
              label={
                <Text>
                  Descargue y realice el Llenado del formulario de Registro
                  integral de persona natural.
                </Text>
              }
            >
              1
            </CustomMobileTooltip>
            <CustomMobileTooltip
              label={
                <Text>
                  Recibirás un correo de confirmación con el link de ingreso y
                  credenciales para hacer uso de la plataforma y realizar la
                  descarga del API para integrarte a nuestro sistema y recibir
                  PAGOS, por medio de Enlaces de Cobro a través de QR, Redes
                  Sociales, Correo Electrónico y Ecommerce.
                </Text>
              }
            >
              2
            </CustomMobileTooltip>
            <CustomMobileTooltip
              label={
                <Text>
                  Envíe el formulario a nuestro correo
                  atencionalcliente@pagoflash.com , Junto a los siguientes
                  recaudos:
                  <OrderedList>
                    <ListItem>
                      Acta constitutiva y estatutos sociales debidamente
                      inscritos en el Registro Mercantil competente.
                    </ListItem>

                    <ListItem>
                      Documento inscrito en el Registro Mercantil, o copia de
                      poder autenticado, donde se identifique el sujeto que
                      puede representar a la empresa así como las facultades que
                      detenta.
                    </ListItem>

                    <ListItem>
                      Cédula de Identidad del Representante Legal.
                    </ListItem>

                    <ListItem>
                      Registro Único de Información Fiscal (RIF) vigente.
                    </ListItem>

                    <ListItem>
                      Estados Financieros de los tres (3) últimos ejercicios
                      económicos.
                    </ListItem>
                  </OrderedList>
                </Text>
              }
            >
              3
            </CustomMobileTooltip>
          </Box>
        </Box>
      </Section> */}

      {/* <Section>
        <SectionHeader color="gray.700" showDivider textAlign="center">
          Servicios que ofrecemos
        </SectionHeader>

        <SimpleGrid
          maxW="6xl"
          mx="auto"
          alignItems="flex-start"
          gridTemplateColumns={["repeat(1, 1fr)", null, "repeat(5, 1fr)"]}
          my="4"
          justifyContent="center"
          textAlign={"center"}
          gridRowGap="8"
        >
          {siteServices.map((item, i) => (
            <VStack spacing="4" key={i} mx="auto">
              <PagoShopTooltip
                isOpen={undefined}
                label={<Text>{item.content}</Text>}
              >
                <Center flexDir="column">
                  <Image src={item.icon} w={{ base: "80px", lg: "100px" }} />
                  <Text
                    color="gray.700"
                    fontSize={{ base: "2xl", lg: "3xl" }}
                    minW="150px"
                  >
                    {item.text}
                  </Text>
                </Center>
              </PagoShopTooltip>
            </VStack>
          ))}
        </SimpleGrid>
      </Section> */}

      {/* <CustomParallax>
        <Section position="relative" zIndex="1">
          <SectionHeader color="white" textAlign="center" showDivider mb="16">
            Beneficios
          </SectionHeader>

          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 4 }}
            mx="auto"
            maxW="5xl"
            gridRowGap={8}
          >
            {pagoShopBenefits.map((benefit, i) => (
              <Box key={i}>
                <Text
                  fontSize="3xl"
                  fontWeight="medium"
                  color="white"
                  textAlign="center"
                  mb="8"
                  className={noWrapClass}
                >
                  {benefit.text}
                </Text>
                <Image src={benefit.image} mx="auto" mb="8" />
              </Box>
            ))}
          </SimpleGrid>
        </Section>
      </CustomParallax> */}

      <ConditionsSection />

      <Section spacing="sm" textAlign="center">
        <SectionHeader color="gray.700">
          <Text as="span" color="orange.500">
            Nuestras condiciones se adaptan a ti
          </Text>{" "}
          <br /> y aplican a todos nuestros productos
        </SectionHeader>
      </Section>

      <PreFooterSection />

      <Footer />
    </Box>
  );
};

export default PagoFlashNomina;
