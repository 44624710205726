import {
  FormControl,
  Input,
  Select,
  Button,
  Text,
  Box,
} from "@chakra-ui/react";
import CustomParallax from "../components/custom/CustomParallax";
import Section from "../components/section/Section";
import SectionHeader from "../components/section/SectionHeader";

import bgHero from "../assets/images/hero-home.jpg";
import Footer from "../components/layout/Footer";

const FormPage = () => {
  return (
    <Box backgroundImage={`url(${bgHero})`} width="100%" bgSize="cover">
      <Box
        my={{ base: "24", lg: "48" }}
        maxW="2xl"
        mx={"auto"}
        overflow="auto"
        position="relative"
        borderRadius={"5px"}
      >
        <CustomParallax backgroundSize="1000px">
          <Section spacing="sm">
            <SectionHeader
              color="white"
              textAlign="center"
              showDivider
              fontSize="4xl"
            >
              Hola 🙂<br></br> Aquí puedes agendar tu asesoría <br></br>
            </SectionHeader>
            <Text
              fontSize="xl"
              textAlign="center"
              color="white"
              fontWeight="medium"
            >
              Nuestros ejecutivos pueden responder todas tus dudas a través de
              una llamada, WhatsApp o Skype. ¿Quieres agendar una asesoría?
              Aprende sobre nuestros productos y toma tu decisión.
            </Text>
          </Section>
        </CustomParallax>
        <Box bgColor={"white"} px="8" py={"8"}>
          <Text fontSize="3xl" textAlign="center">
            ¡Es absurdamente sencillo!
          </Text>
          <FormControl isRequired marginTop="6">
            <Input id="name" placeholder="Nombre*" my="4"></Input>
            <Select
              placeholder="Modalidad de negocio*"
              id="business"
              color="black"
              my="4"
            >
              <option value="PN" color="black">
                Persona Natural
              </option>
              <option value="PJ">Persona Jurídica</option>
            </Select>
            <Input
              id="nameBusiness"
              placeholder="¿Cómo se llama tu negocio?"
              my="4"
            ></Input>
            <Select
              placeholder="¿Qué medio de contacto prefieres?*"
              id="business"
              color="gray.400"
              textColor="black"
              my="4"
            >
              <option value="LLT">Llamada telefonica</option>
              <option value="CW">Chat de Whatsapp</option>
            </Select>
            <Input id="correo" placeholder="Correo electrónico*" my="4"></Input>
            <Input id="phone" placeholder="Teléfono*" my="4"></Input>

            <Button
              mt={"4"}
              px="12"
              bg={"orange.500"}
              color={"white"}
              _hover={{
                bg: "orange.600",
              }}
            >
              ¡ENVIAR!
            </Button>
          </FormControl>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default FormPage;
