import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Image,
  Flex,
} from "@chakra-ui/react";
import Hero from "../components/layout/Hero";

import ProductStepsSection from "../components/section/ProductStepsSection";
import { pagoClickSteps } from "../data";
import SectionHeader from "../components/section/SectionHeader";
import Section from "../components/section/Section";
import CustomParallax from "../components/custom/CustomParallax";
import DeviceFrame from "../components/devices/DeviceFrame";

import bgHero from "../assets/images/hero-pagoclick.jpg";
import pagoClick from "../assets/images/PagoClick.png";
import tarjetaPagoFlash from "../assets/images/tarjeta-pagoflash.png";
import Footer from "../components/layout/Footer";
import PreFooterSection from "../components/section/PreFooterSection";
import ConditionsSection from "../components/section/ConditionsSection";
import { routes } from "../routes/routes";
import { useNavigate } from "react-router-dom";
import qrPagoFlashAPP from "../assets/images/qrPagoFlashAPP.png";
import pagoFlashWeb from "../assets/images/pagoFlashWeb.png";
import masterTarjeta from "../assets/images/masterTarjeta.png";

// interface PagoClickOption {
//   videoUrl: string;
//   title: string;
//   text: string;
//   tip: string;
// }

interface PagoClickBenefit {
  title: string;
  text: string;
  img?: string;
}

// const pagoClickOptions: PagoClickOption[] = [
//   {
//     videoUrl: "https://www.youtube.com/embed/rdWJOv5_olw",
//     title: "Monto fijo",
//     text: "Enlace con el monto del producto o servicio configurado.",
//     tip: "Ideal para mejor manejo de ventas ¡Sencillo!",
//   },
//   {
//     videoUrl: "https://www.youtube.com/embed/AKOIfSsRWEI",
//     title: "Sin Monto",
//     text: "Enlace sin un monto predeterminado.",
//     tip: "Ideal para donaciones y bazares.",
//   },
//   {
//     videoUrl: "https://www.youtube.com/embed/ahALwZCb9wU",
//     title: "Catálogo",
//     text: "Enlace con el nombre de tu negocio que te permite agrupar todos tus PagoClick en un solo lugar.",
//     tip: "Ideal para mejor manejo de ventas ¡Sencillo!",
//   },
// ];

const pagoClickBenefits1: PagoClickBenefit[] = [
  // {
  //   title: "PagoFlash APP ",
  //   text: "Los usuarios tendrán a su disposición la App para realizar pagos mediante el escaneo de códigos QR en los establecimientos afiliados.",
  //   img: qrPagoFlashAPP,
  // },
  {
    title: "PagoFlash en línea",
    text: "Plataforma web en la que el cliente puede gestionar su cuenta, realizar consultas, pagar servicios e iniciar transferencias. Además, puede administrar su propia información de seguridad y actualizar sus datos personales.",
    img: pagoFlashWeb,
  },
];

const pagoClickBenefits2: PagoClickBenefit[] = [
  {
    title: "PagoFlash Maestro ",
    text: "Puedes solicitar tu tarjeta de débito de una forma fácil y segura dentro de nuestra plataforma.",
    img: masterTarjeta,
  },
];

// const pagoClickBenefitsRight: PagoClickBenefit[] = [
//   {
//     title: "Hazlo a tu medida",
//     text: "Crea, envía y usa el enlace desde cualquier dispositivo móvil, PC o tablet.",
//   },
//   {
//     title: "Hazlo a tu medida",
//     text: "Configura el formulario del enlace con los datos que necesites de tu cliente.",
//   },
//   {
//     title: "Ten mayor inteligencia de negocio",
//     text: "Analiza la información de tus ventas y descubre el comportamiento de compra de tu cliente.",
//   },
// ];

const planilla = require("../assets/doc/planillaPersonaNatural.pdf");

const PagoClickPage = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Hero backgroundImage={bgHero}>
        <Box textAlign="center">
          <Heading size="3xl" color="white" textAlign="center" mb="8">
            PagoFlash Wallet
          </Heading>
          <Text
            color={"white"}
            fontWeight={600}
            lineHeight={1.3}
            fontSize={{ base: "2xl", md: "3xl" }}
            mb="8"
            opacity="0.85"
          >
            La Cuenta Transaccional de Persona Natural, es un producto
            financiero, destinado a la recepción y movilización de fondos en
            moneda nacional, la misma cuenta con el respaldo de una plataforma
            tecnológica absurdamente sencilla y enfocada a brindad la mejor
            experiencia al usuario.{" "}
          </Text>
          <Text fontSize="2xl" maxW="xl" mx="auto" mb="8">
            Puedes descargar la planilla de apertura de cuenta persona natural
            haciendo click en el botón de abajo
          </Text>
          <Stack
            direction={{ base: "column", lg: "row" }}
            justifyContent="center"
            spacing="8"
          >
            <a
              href={planilla}
              target="_blank"
              download={"planilla.pdf"}
              rel="noreferrer"
            >
              <Button
                rounded="sm"
                variant="outline"
                color="white"
                borderWidth="2px"
                size="lg"
                px="16"
                _hover={{ bg: "white", color: "black" }}
              >
                Descargar planilla
              </Button>
            </a>

            <Button
              bg="orange.500"
              rounded="sm"
              color="white"
              size="lg"
              _hover={{ bg: "orange.600" }}
              onClick={() => navigate(routes.asesoria)}
            >
              Agenda una asesoría
            </Button>
          </Stack>
        </Box>
      </Hero>

      {/* <Box id="hero-bottom" position="relative" zIndex="1">
        <ProductStepsSection heading="¿Como funciona?" steps={pagoClickSteps} />

        <CustomParallax>
          <Section spacing="sm">
            <SectionHeader
              color="white"
              textAlign="center"
              showDivider
              mb={{ base: "2", sm: "16" }}
            >
              Bondades que te ofrece pago PagoFlash Wallet
            </SectionHeader>

            <SimpleGrid
              columns={{ base: 1, lg: 2 }}
              mx="auto"
              maxW="5xl"
              gridGap="8"
            >
              <SimpleGrid
                templateRows={["repeat(2, 1fr)"]}
                textAlign="center"
                gridRowGap="4"
                alignItems={"center"}
              >
                {pagoClickBenefits1.map((benefit) => (
                  <Flex alignItems={"center"}>
                    <Image
                      src={benefit.img}
                      boxSize={{ base: "0px", lg: "160px" }}
                    ></Image>
                    <Box key={benefit.title}>
                      <Text color="white" fontSize="2xl" fontWeight="medium">
                        {benefit.title}
                      </Text>
                      <Text color="white" fontSize="xl">
                        {benefit.text}
                      </Text>
                    </Box>
                  </Flex>
                ))}
              </SimpleGrid>
              <Box textAlign="center">
                <DeviceFrame device="iPhone X" color="black" size={1.8}>
                  <Image src={pagoClick}></Image>
                </DeviceFrame>
              </Box>
            </SimpleGrid>

            <SimpleGrid
              columns={{ base: 1, lg: 2 }}
              mx="auto"
              maxW="5xl"
              gridGap="8"
              mt={"12"}
            >
              <SimpleGrid
                templateRows={["repeat(1, 1fr)"]}
                textAlign="center"
                gridRowGap="4"
                alignItems={"center"}
              >
                {pagoClickBenefits2.map((benefit) => (
                  <Flex alignItems={"center"}>
                    <Image
                      src={benefit.img}
                      mr="4"
                      boxSize={{ base: "0px", lg: "160px" }}
                    ></Image>
                    <Box key={benefit.title}>
                      <Text color="white" fontSize="2xl" fontWeight="medium">
                        {benefit.title}
                      </Text>
                      <Text color="white" fontSize="xl">
                        {benefit.text}
                      </Text>
                    </Box>
                  </Flex>
                ))}
              </SimpleGrid>
              <Box textAlign="center" m={"auto"}>
                <Image src={tarjetaPagoFlash}></Image>
              </Box>
            </SimpleGrid>
          </Section>
        </CustomParallax>
      </Box> */}

      {/* <Section>
        <SectionHeader color="gray.700" showDivider textAlign="center">
          Opciones de{" "}
          <Text color="teal.300" as="span">
            PagoClick
          </Text>
        </SectionHeader>

        <SimpleGrid
          columns={{ base: 1, lg: 3 }}
          maxW="6xl"
          spacing={{ base: "12", lg: "4", xl: "12" }}
          mx="auto"
          my="24"
        >
          {pagoClickOptions.map((option) => (
            <Box textAlign="center" key={option.title}>
              <Box mb="4">
                <iframe
                  width="auto"
                  height="200"
                  src={option.videoUrl}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ margin: "auto" }}
                ></iframe>
              </Box>
              <Text fontSize="3xl" fontWeight="medium">
                {option.title}
              </Text>
              <Text fontSize="lg" fontWeight="medium" maxW="83%" mx="auto">
                {option.text}
              </Text>
              <Text color="teal.400" fontSize="lg" mt="4">
                <Text as="span" fontWeight="medium">
                  #PagoTip
                </Text>{" "}
                {option.tip}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Section> */}

      <ConditionsSection />

      <PreFooterSection />

      <Footer />
    </Box>
  );
};

export default PagoClickPage;
