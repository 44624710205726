import ContactoPage from "../pages/ContactoPage";
import HomePage from "../pages/HomePage";
import Loginpage from "../pages/Loginpage";
import PagoClickPage from "../pages/PagoClickPage";
import PagoMailPage from "../pages/PagoMailPage";
import SoportePage from "../pages/SoportePage";
import PagoShopPage from "../pages/PagoShopPage";
import ProductsPage from "../pages/ProductsPage";
import NosotrosPage from "../pages/NosotrosPage";
import FormPage from "../pages/FormPage";
import TermsAndConditionsPage from "../pages/TermsAndConditionsPage";
import PagoFlashNomina from "../pages/PagoFlashNomina";
import Privacy from "../pages/Privacy";

interface DirRoutes {
  path: string;
  element: JSX.Element;
}

export const routes = {
  home: "/",
  wallet: "/soluciones-de-pago/PagoFlash-Wallet",
  //contacto: "/contacto",
  business: "/soluciones-de-pago/PagoFlash-Business",
  login: "/login",
  soporte: "/soporte",
  commerce: "/soluciones-de-pago/commerce",
  nomina: "PagoFlash-Nomina",
  products: "/soluciones-de-pago",
  //nosotros: "/nosotros",
  asesoria: "/agenda-una-asesoria",
  toc: "/terminos-y-condiciones",
  privacy: "/privacidad",
};

export const publicDir: DirRoutes[] = [
  { path: routes.home, element: <HomePage /> },
  //{ path: routes.contacto, element: <ContactoPage /> },
  { path: routes.wallet, element: <PagoClickPage /> },
  { path: routes.business, element: <PagoMailPage /> },
  { path: routes.login, element: <Loginpage /> },
  { path: routes.soporte, element: <SoportePage /> },
  { path: routes.commerce, element: <PagoShopPage /> },
  { path: routes.nomina, element: <PagoFlashNomina /> },
  { path: routes.products, element: <ProductsPage /> },
  //{ path: routes.nosotros, element: <NosotrosPage /> },
  { path: routes.asesoria, element: <FormPage /> },
  { path: routes.toc, element: <TermsAndConditionsPage /> },
  { path: routes.privacy, element: <Privacy /> },
  /* { path: routes.login, element: <LoginPage /> },
  { path: routes.register, element: <RegisterPage /> },
  { path: routes.payment, element: <PaymentPage /> } */
];

export const privateDir: DirRoutes[] = [
  //{ path: routes.home, element: <HomePage /> },
];
