import { ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Center } from "@chakra-ui/react";
import { useState } from "react";
import { Bounce } from "react-awesome-reveal";
import { useScrollPosition } from "../../hooks/useScrollPosition";

const CustomScrollUp = () => {
  const [visible, setVisible] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y >= window.screen.height - 150) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    },
    undefined,
    { useWindow: true }
  );

  const onClick = () => {
    const scrollTop = document.getElementById("scroll-top");

    scrollTop?.scrollIntoView({ behavior: "smooth" });
  };

  if (!visible) {
    return null;
  }

  return (
    <Box position="fixed" bottom="8" right="8" zIndex="20">
      <Bounce>
        <Center
          onClick={onClick}
          color="white"
          bg="black"
          px="3"
          py="3"
          fontSize="2xl"
          cursor="pointer"
          opacity={0.7}
          _hover={{
            bg: "white",
            color: "black",
          }}
          transition="1s"
        >
          <ChevronUpIcon />
        </Center>
      </Bounce>
    </Box>
  );
};

export default CustomScrollUp;
