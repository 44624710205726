import { Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ChildrenJSX } from "../../interfaces/childrenJsx";
import CustomScrollUp from "../custom/CustomScrollUp";
import { Navbar } from "../navbar";

const AppLayout = ({ children }: ChildrenJSX) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.key]);

  return (
    <Flex flexDirection="column" height="100vh">
      <div id="scroll-top"></div>
      <Navbar />

      <CustomScrollUp />

      <Flex flex="1" direction="column">
        {children}
      </Flex>
    </Flex>
  );
};

export default AppLayout;
