import { Box, Stack, Center, Button, Text, Image } from "@chakra-ui/react";
import { Slide } from "react-awesome-reveal";
import { howToUseItems } from "../../data";

const ProductsDescriptionSection = () => {
  return (
    <Box textAlign="center" py="16" bg="brand.500" color="black.500">
      <Slide duration={1000} direction="up" triggerOnce>
        <Stack
          direction={{ base: "column", lg: "row" }}
          justifyContent="center"
          spacing={{ base: "12", lg: "8" }}
          alignItems={{ base: "center", lg: "flex-start" }}
        >
          {howToUseItems.map(({ links, image, title, description }, i) => (
            <Box key={i} w="100%">
              <Center fontSize="6xl" mb="8">
                <Image src={image} width="110px"></Image>
              </Center>
              <Center flexDirection="column">
                <Text
                  fontSize={{ base: "2xl", lg: "4xl" }}
                  fontWeight="medium"
                  pb={"5"}
                >
                  {title}
                </Text>
                <Text fontSize={{ base: "lg", lg: "xl" }} fontWeight="regular">
                  {description}
                </Text>
              </Center>
            </Box>
          ))}
        </Stack>
        {/* <Box alignItems={"center"}>
          <Button
            marginTop="8"
            display={{ base: "none", md: "inline-flex" }}
            fontSize={"xl"}
            fontWeight="medium"
            borderRadius="base"
            px="8"
            py="6"
            color={"white"}
            bg={"orange.500"}
            //href={"#"}
            _hover={{
              bg: "orange.300",
            }}
          >
            Conócelos
          </Button>
        </Box> */}
      </Slide>
    </Box>
  );
};

export default ProductsDescriptionSection;
